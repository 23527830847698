import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoggedInMainScreen from './LoggedInMainScreen'
import LoggedOutMainScreen from './LoggedOutMainScreen'

const MainScreen = () => {
	const { isLoading } = useAuth0()

	if (isLoading) return <div>Loading...</div>
	return (
		<div>
			<LoggedInMainScreen />
			<LoggedOutMainScreen />
		</div>
	)
}

export default MainScreen
