import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
//import { Row, Col } from 'react-bootstrap'
import '../App.css'
import Movie from '../components/Movie'
import axios from 'axios'
import Search from '../components/Search'

const HomeScreen = () => {
	const [movies, setMovies] = useState([])
	const [searchField, setSearchField] = useState('')
	//	const [totalMovies, setTotalMovies] = useState(0)

	const handleChange = (e) => {
		setSearchField(e.target.value)
	}

	/*
	useEffect(() => {
		const fetchMovies = async () => {
			const { data } = await axios.get('/api/movies')

			setMovies(data)
		}

		fetchMovies()
	}, [])
*/

	useEffect(() => {
		const fetchMovies = async () => {
			axios
				.get('https://nollywood.eastdonriver.com/naija/movies')
				.then((response) => {
					setMovies(response.data)
					//setTotalMovies(response.data.length)
				})
		}

		fetchMovies()
	}, [])

	// Different Method
	/*
const getMovies = () => {
  Axios.get('http://localhost:3300/movies').then((response) => {
    setMovieList(response.data)
  })
}
*/

	const filteredMovies = movies.filter((movie) =>
		movie.title.toLowerCase().includes(searchField.toLowerCase())
	)

	//console.log(movies.length)
	//console.log(`Total Movies: ${totalMovies}`)

	return (
		<div>
			<Search handleChange={handleChange} />
			<div className='MovieList'>
				{filteredMovies.map((movie) => (
					<Movie key={movie.id} movie={movie} />
				))}
			</div>
		</div>
	)
}

export default HomeScreen
