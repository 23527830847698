import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import { Link } from 'react-router-dom'

const Navigator = () => {
	const { isAuthenticated } = useAuth0()

	return (
		<div>
			<Navbar variant='dark'>
				<Container>
					<Nav className='me-auto'>
						<Nav.Link as={Link} to='/'>
							Movies
						</Nav.Link>
						<Nav.Link as={Link} to='/data'>
							Data Manager
						</Nav.Link>
					</Nav>
					{isAuthenticated ? <LogoutButton /> : <LoginButton />}
				</Container>
			</Navbar>
		</div>
	)
}

export default Navigator
