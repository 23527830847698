import React from 'react'
import { Image } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
//import LoginButton from '../components/LoginButton'

const LoggedOutMainScreen = () => {
	const { isAuthenticated } = useAuth0()

	return (
		!isAuthenticated && (
			<div>
				<p>Please log in to access.</p>
				<Image src='/images/Lagos_skyline.jpg' />
			</div>
		)
	)
}

export default LoggedOutMainScreen
