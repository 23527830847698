import React from 'react'
//import { InputGroup, FormControl } from 'react-bootstrap'
import '../App.css'

const Search = ({ handleChange }) => {
	return (
		<div>
			<input
				className='search'
				type='search'
				placeholder='Search'
				onChange={handleChange}
			/>
		</div>
	)
}

export default Search
