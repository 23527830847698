import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
//import HomeScreen from './screens/HomeScreen'
import MovieScreen from './screens/MovieScreen'
import MainScreen from './screens/MainScreen'
import Navigator from './components/Navigator'
import DataManager from './screens/DataManager'
import { ProtectedRoute } from './components/ProtectedRoute'

const App = () => {
	return (
		<Router>
			<div className='App'>
				<Header />
				<Container>
					<Navigator />
					<Routes>
						<Route path='/' element={<MainScreen />} />
						<Route
							path='/movie/:id'
							element={<ProtectedRoute component={MovieScreen} match />}
						/>
						<Route
							path='/data/'
							element={<ProtectedRoute component={DataManager} />}
						/>
					</Routes>
				</Container>
				<Footer />
			</div>
		</Router>
	)
}

export default App
