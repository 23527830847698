import React from 'react'
import DataForm from '../components/DataForm'

const DataManager = () => {
	return (
		<div>
			<p>Data Manager</p>
			<DataForm />
		</div>
	)
}

export default DataManager
