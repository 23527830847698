import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import HomeScreen from './HomeScreen'
//import Navigator from '../components/Navigator'

const LoggedInMainScreen = () => {
	const { isAuthenticated } = useAuth0()

	return (
		isAuthenticated && (
			<div>
				<HomeScreen />
			</div>
		)
	)
}

export default LoggedInMainScreen
