import axios from 'axios'
import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'

const DataForm = () => {
	const [title, setTitle] = useState('')
	const [year, setYear] = useState('')
	const [image, setImage] = useState('')
	const [wikipedia, setWikipedia] = useState('')
	const [imdb, setIMDB] = useState('')
	const [netflix, setNetflix] = useState('')

	const addBook = () => {
		axios.post('https://nollywood.eastdonriver.com/naija/movies/', {
			title: title,
			year: year,
			image: image,
			wikipedia: wikipedia,
			imdb: imdb,
			netflix: netflix,
		})
	}

	return (
		<div>
			<Container>
				<Form onSubmit={addBook}>
					<Row className='mb-3'>
						<Form.Group as={Col} controlId='text'>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Title'
								onChange={(event) => {
									setTitle(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Year</Form.Label>
							<Form.Control
								type='number'
								placeholder='Year'
								onChange={(event) => {
									setYear(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Poster</Form.Label>
							<Form.Control
								type='text'
								placeholder='/images/'
								onChange={(event) => {
									setImage(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Wikipedia</Form.Label>
							<Form.Control
								type='text'
								placeholder='https://en.wikipedia.org/wiki/'
								onChange={(event) => {
									setWikipedia(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>IMDB</Form.Label>
							<Form.Control
								type='text'
								placeholder='https://imdb.com/'
								onChange={(event) => {
									setIMDB(event.target.value)
								}}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId='formGridText'>
							<Form.Label>Netflix</Form.Label>
							<Form.Control
								type='text'
								placeholder='https://netflix.com/'
								onChange={(event) => {
									setNetflix(event.target.value)
								}}
							/>
						</Form.Group>
					</Row>

					<Button variant='primary' type='submit'>
						Submit
					</Button>
				</Form>
			</Container>
		</div>
	)
}

export default DataForm
