import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const Movie = ({ movie }) => {
	return (
		<div>
			<Card className='HomeCards' style={{ backgroundColor: '#282c34' }}>
				<Link to={`/movie/${movie.id}`}>
					<Card.Img className='Photo' src={movie.image} variant='top' />
				</Link>
				<Card.Body>
					<Link to={`/movie/${movie.id}`}></Link>
				</Card.Body>
			</Card>
		</div>
	)
}

export default Movie
